import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import PrivateRoute from "./PrivateRoute";
import China from "./pages/China";
import CustomerService from "./pages/CustomerService";
import Admin from "./pages/Admin";
import Register from "./pages/Register";
import Shipment from "./pages/Shipment";
import Summary from "./pages/Summary";
import Setting from "./pages/Setting";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<PrivateRoute element={<Register />} />} />
        <Route path="/" element={<PrivateRoute element={<Home />} />} />
        <Route path="/china" element={<PrivateRoute element={<China />} />} />
        <Route path="/customerService" element={<PrivateRoute element={<CustomerService />} />} />
        <Route path="/shipment" element={<PrivateRoute element={<Shipment />} />} />
        <Route path="/admin" element={<PrivateRoute element={<Admin />} />} />
        <Route path="/summary" element={<PrivateRoute element={<Summary />} />} />
        <Route path="/Setting" element={<PrivateRoute element={<Setting />} />} />
      </Routes>
    </Router>
  );
}

export default App;
