import React, { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

function Summary() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const monthToNumber = (month) => {
    const months = {
      JAN: 1,
      FEB: 2,
      MAR: 3,
      APR: 4,
      MAY: 5,
      JUN: 6,
      JUL: 7,
      AUG: 8,
      SEP: 9,
      OCT: 10,
      NOV: 11,
      DEC: 12,
    };
    return months[month] || 0;
  };

  const sortByOrderNumber = (data) => {
    return data.sort((a, b) => {
      const [aMonth, aNumber] = [
        a.OrderNumber.slice(0, 3),
        parseInt(a.OrderNumber.slice(3)),
      ];
      const [bMonth, bNumber] = [
        b.OrderNumber.slice(0, 3),
        parseInt(b.OrderNumber.slice(3)),
      ];

      const aMonthNum = monthToNumber(aMonth);
      const bMonthNum = monthToNumber(bMonth);

      if (aMonthNum !== bMonthNum) {
        return bMonthNum - aMonthNum; // Sort by month first (descending)
      }
      return bNumber - aNumber; // Then by the number (descending)
    });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/unshippedItems`);
      const fetchedData = response.data.map((item) => ({
        ...item,
        imageUrl1: `${apiUrl}/uploads/${item.ImageFilename1}`, // Construct the first image URL
        imageUrl2: `${apiUrl}/uploads/${item.ImageFilename2}`, // Construct the image URL
      }));
      const sortedData = sortByOrderNumber(fetchedData);

      setData(sortedData); // Set the sorted data
      setFilteredData(sortedData); // Initialize filteredData with all products
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors(error.response?.data?.message || "An error occurred");
    }
  };
  useEffect(() => {
    fetchData();
    total();
  }, []);

  const total = () => {
    let sum = 0;

    data.forEach((item) => {
      const price = Number(item.Price) || 0;
      const com1 = Number(item.com1) || 0;
      const com2 = Number(item.com2) || 0;
      const deliveryFee = Number(item.DeliveryFee) || 0;

      sum += price + com1 + com2 + deliveryFee;

      console.log("Price:", price, "Com1:", com1, "Com2:", com2, "DeliveryFee:", deliveryFee);
    });

    return sum;
  };

  const totalYuan = (item) => {
    // Convert Price, com1, and com2 to numbers to ensure numeric addition
    const price = Number(item.Price) || 0;
    const com1 = Number(item.com1) || 0;
    const com2 = Number(item.com2) || 0;
  
    const total = price + com1 + com2;
    console.log("Calculated Total:", total, "Price:", price);
    return total;
  };
  

  return (
    <div className="items-center flex flex-col justify-center mt-1 overflow-auto">
      <div className=" border-1 border-red-200 mt-20 w-3/4 mb-20 p-5 rounded-md overflow-auto">
        <h1 className="font-bold text-2xl">China</h1>
        <p className="font-bold text-xl">unshipped</p>
        <table className="border-collapse table-auto w-full mb-10 overflow-x-scroll">
          <thead>
            <tr>
              <th className="border-b-1 pb-3 border-slate-600 text-left">#</th>
              <th className="border-b-1 pb-3 border-slate-600 text-left">
                Brand
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left">
                Model
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0">
                Storage
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0">
                Ram
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0">
                Sim
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0">
                Price
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0">
                Delivery Fee
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0">
                Total Price
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0">
                Delivery Status
              </th>
              <th className="border-b-1 pb-3 border-slate-600 text-left m-0">
                Order Status
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td className="border-b-1 pb-3 border-slate-600 text-left font-bold text-slate-800 m-0">
                  {item.OrderNumber}
                </td>
                <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  {item.Brand}
                </td>
                <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  {item.Model}
                </td>
                <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  {item.Storage}
                </td>
                <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  {item.Ram}
                </td>
                <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  {item.Sim}
                </td>
                <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  {item.Price}
                </td>
                <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  {item.DeliveryFee}
                </td>
                <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  {totalYuan(item)}
                </td>
                <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  {item.DeliveryStat}
                </td>
                <td className="border-b-1 pb-3 border-slate-600 text-left m-0">
                  {item.Status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex-row flex w-full">
          <h1 className="text-2xl font-bold">Total Unshipped: </h1>
          <p className="text-2xl">{filteredData.length}</p>
        </div>
      </div>
    </div>
  );
}

export default Summary;
