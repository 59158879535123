import React, { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {/* Button to toggle sidebar */}
      <button
        className="p-2 bg-black text-white top-20 left-4 z-50"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <CloseIcon/> : "Open Menu"}
      </button>

      {/* Sidebar */}
      <div
        className={`fixed top-30 left-0 w-2/3 md:w-1/5 bg-white h-screen text-white flex flex-col pt-12 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-40`}
      >
        <a
          href="/setting"
          className="p-3 bg-black rounded m-2 hover:bg-gray-400"
        >
          Setting
        </a>
        <a
          href="/summary"
          className="p-3 bg-black rounded m-2 hover:bg-gray-400"
        >
          Summary
        </a>
        <a
          href="/register"
          className="p-3 bg-black rounded m-2 hover:bg-gray-400"
        >
          Register
        </a>
        {/* <a
          href="/china"
          className="p-3 bg-black rounded m-2 hover:bg-gray-400"
        >
          China
        </a>
        <a
          href="/shipment"
          className="p-3 bg-black rounded m-2 hover:bg-gray-400"
        >
          Shipment
        </a> */}
      </div>

      {/* Overlay when sidebar is open */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </>
  );
}

export default Sidebar;
