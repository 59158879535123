import React from "react";
import EtTable from "../component/EtTable";
import CnTable from "../component/CnTable";
import Header from "../component/Header";
import Sidebar from "../component/Sidebar";

function Summary() {
  return (
    <div>
      <Header />
      <Sidebar />
      <CnTable />
      <EtTable />
    </div>
  );
}

export default Summary;
