import React from 'react'
import Header from '../component/Header'

function CustomerService() {
    return (
        <div>
            <Header/>
        </div>
    )
}

export default CustomerService
